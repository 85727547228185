<template>
  <div v-if="regionUser">

    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <h4 id="top">Region user: <small>{{ regionUser.user_name }} - {{ regionUser.region_name }}</small></h4>
    </div>
    <div class="card">
      <div class="rainbow-background"></div>
      <table  class="card-table table">
        <tbody class="text-left">
        <tr>
          <th scope="col">ID</th>
          <td>{{ regionUser.id }}</td>
        </tr>
        <tr>
          <th scope="col">User</th>
          <td><router-link :to="{ name: 'UserShow', params: { id: regionUser.user_id } }">{{ regionUser.user_name }}</router-link></td>
        </tr>
        <tr>
          <th scope="col">User Role</th>
          <td>{{ regionUser.user_role }}</td>
        </tr>
        <tr>
          <th scope="col">Region</th>
          <td><router-link :to="{ name: 'RegionShow', params: { id: regionUser.region_id } }">{{ regionUser.region_name}}</router-link></td>
        </tr>
        <tr>
          <th scope="col">Created At</th>
          <td>{{ new Date(regionUser.created_at).toDateString() }}, {{ new
            Date(regionUser.created_at).toLocaleTimeString() }}</td>
        </tr>
        <tr>
          <th scope="col">Updated At</th>
          <td>{{ new Date(regionUser.updated_at).toDateString() }}, {{ new
            Date(regionUser.updated_at).toLocaleTimeString() }}</td>
        </tr>
        </tbody>
      </table>
      <div class="card-footer">
        <div class="btn-group float-right">
          <router-link :to="{ name: 'RegionUserIndex' }" tag="button" class="btn btn-secondary">Back</router-link>
          <router-link :to="{ name: 'RegionUserEdit' }" tag="button" class="btn btn-primary">Edit</router-link>
          <button v-on:click="$refs.regionUserDeleteModal.show()" type="button" class="btn btn-danger">Delete</button>
        </div>
      </div>
    </div>

    <!-- Modal Component -->
    <b-modal ref="regionUserDeleteModal" header-bg-variant="danger" header-text-variant="light" title="Confirm Delete" ok-variant="danger" ok-title="Delete" @ok="deleteRegionUser" centered>
      <p>Are you sure you want to delete this User Role?</p>
    </b-modal>

  </div>
  <loading-overlay v-else></loading-overlay>
</template>

<script>
import { RegionUserService } from '@/common/services/region_user.service';
import LoadingOverlay from '@/common/components/loading-overlay.vue';

export default {
  name: 'RegionUserShow',
  components: {
    LoadingOverlay,
  },
  data() {
    return {
      regionUser: null,
    };
  },
  created() {
    this.getRegionUser();
  },
  methods: {
    getRegionUser() {
      RegionUserService.get(this.$route.params.id)
        .then((response) => {
          this.regionUser = response.data;
        });
    },
    deleteRegionUser() {
      RegionUserService.destroy(this.$route.params.id)
        .then((response) => {
          this.$router.push({ name: 'RegionUserIndex' });
          this.$notify({
            title: `Deleted ${response.data.title}`,
            text: 'Successfully deleted User Role.',
            type: 'success',
            width: 350,
          });
        });
    },
  },
};

</script>

<style lang="scss" scoped>
  @import "../../styles/views/trainee_roles";
</style>
